let fieldsAreInitialized = false;
console.log('test');
function makeFieldsReadonly() {
  const readOnlyTextFields = document.querySelectorAll(
    '.gfield.gf_readonly textarea, .gfield.gf_readonly input'
  );

  if (0 < readOnlyTextFields.length) {
    readOnlyTextFields.forEach(function (readOnlyTextField) {
      readOnlyTextField.setAttribute('readonly', 'readonly');
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function addFormEvents() {
  const fields = document.querySelectorAll(
    '.gfield:not(.gfield--radio):not(.gfield--select)'
  );

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      if (field.classList.contains('gfield--fileupload')) {
        field.querySelector('.gform_drop_instructions').textContent =
          wp.i18n._x(
            'Drag and drop file to upload',
            'drag and drop instruction',
            'vtx'
          );
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
      ];

      for (let i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      field.classList.add('initialized');

      const label = field.querySelector('.gfield_label');
      const input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        changeFormLabel(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            const sublabel = subfield.querySelector('label');
            const subinput = subfield.querySelector('input, textarea');
            changeFormLabel(sublabel, subinput);
          });
        }
      }
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  addFormEvents();
  makeFieldsReadonly();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    // Initialize fields events only if they were not already.
    addFormEvents();
    makeFieldsReadonly();
  }
});
